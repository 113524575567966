import React from "react";
import PropTypes from "prop-types";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";

import * as S from "./styles";

const ShareButtons = ({ url }) => {
  return (
    <S.ShareButtons>
      <TwitterShareButton url={url}>
        <FaTwitter />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <FaLinkedinIn />
      </LinkedinShareButton>
      <FacebookShareButton url={url}>
        <FaFacebookF />
      </FacebookShareButton>
      <WhatsappShareButton url={url}>
        <FaWhatsapp />
      </WhatsappShareButton>
    </S.ShareButtons>
  );
};

ShareButtons.propTypes = {
  url: PropTypes.string.isRequired,
};

export default ShareButtons;
