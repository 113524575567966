import styled from "styled-components";

export const PostTitle = styled.h1`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const PostSubheading = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PostInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 3rem 0;

  @media (max-width: 480px) {
    margin-bottom: 0.5rem;
  }
`;

export const AvatarImg = styled.img`
  max-width: 50px;
  border-radius: 100%;
`;

export const Author = styled.div`
  margin-left: 1rem;
`;

export const AuthorName = styled.p`
  margin: 0;
`;

export const AdditionalInformation = styled.small`
  margin: 0;
  color: var(--text-muted-color);
`;

export const Post = styled.section`
  h2,
  h3,
  h4 {
    margin-top: 1.5rem;
  }
`;

export const OtherPostsNav = styled.nav`
  margin-top: 4rem;
`;

export const OtherPostsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`;
