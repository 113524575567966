import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/SEO";
import ShareButtons from "../../components/ShareButtons";

import profilePic from "../../../content/assets/profile-pic.jpg";
import * as S from "./styles";

const BlogPostTemplate = ({ location, data, pageContext }) => {
  const post = data.markdownRemark;
  const { previous, next } = pageContext;
  const url = location.href;

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <S.PostTitle>{post.frontmatter.title}</S.PostTitle>
          <S.PostSubheading>
            <S.PostInfo>
              <S.AvatarImg src={profilePic} alt="avatar" />
              <S.Author>
                <S.AuthorName>{post.frontmatter.author}</S.AuthorName>
                <S.AdditionalInformation>
                  {post.frontmatter.date} • {post.timeToRead} min. read
                </S.AdditionalInformation>
              </S.Author>
            </S.PostInfo>
            <ShareButtons url={url} />
          </S.PostSubheading>
        </header>
        <S.Post dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>

      <S.OtherPostsNav>
        <S.OtherPostsList>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </S.OtherPostsList>
      </S.OtherPostsNav>
    </Layout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      excerpt: PropTypes.string.isRequired,
      frontmatter: PropTypes.shape({
        date: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        author: PropTypes.string.isRequired,
      }).isRequired,
      html: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      timeToRead: PropTypes.number.isRequired,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
    previous: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }).isRequired,
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }),
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      timeToRead
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        author
      }
    }
  }
`;
