import styled from "styled-components";

export const ShareButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;

  * {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--link-color);

    &:hover {
      color: var(--link-hover-color);
    }

    @media (max-width: 480px) {
      width: 1rem;
      height: 1rem;
      margin-bottom: 3rem;
    }
  }
`;
